







































import Vue from 'vue';
import Layout from "@/router/layouts/auth.vue";
import {required, sameAs, minLength} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  components: { Layout },

  created() {
    i18n.locale = router.currentRoute.query.lang.toString();
    localStorage.setItem("language", router.currentRoute.query.lang.toString());
  },

  async mounted(): Promise<void> {
    if (!router.currentRoute.query.token || !router.currentRoute.query.email) {
      router.push("/login");
      return;
    }

    await this.validateToken(router.currentRoute.query.token.toString());
  },

  data() {
    return {
      isSubmitted: false,
      newPassword: "",
      confirmNewPassword: ""
    };
  },

  //@ts-ignore
  validations: {
    newPassword: { required, minLength: minLength(6) },
    confirmNewPassword: { required, minLength: minLength(6), sameAsPassword: sameAs('newPassword') },
  },

  computed: {
    ...mapGetters("userModule", {
      loader: "GET_LOADER"
    })
  },

  methods: {
    ...mapActions('userModule', {
      validateToken: "VALIDATE_USER_RESET_PASSWORD_TOKEN",
      resetUserPassword: "RESET_USER_PASSWORD"
    }),
    async resetPassword(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        reset_password_token: router.currentRoute.query.token,
        new_password: this.newPassword
      }

      await this.resetUserPassword({payload});
    }
  }
});
